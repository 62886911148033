import {
  Col,
  Row,
  Layout,
  Typography,
  Button,
  theme,
  Spin,
  Modal,
  Input,
  message,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { screenWidths, formatXTime } from "../const/const_functions";
import React, { useState, useEffect } from "react";
import { FaRegCalendar, FaMapMarkerAlt } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import EventItemModal from "../components/EventItemModal";
import { useServer } from "../contexts/Server";
import useQuery from "../hooks/useQuery";
import { useCarts } from "../contexts/Carts";
import { useNavigate } from "react-router-dom";

const { useToken } = theme;
const { Title, Text, Link } = Typography;

const EventOverview = () => {
  const navigate = useNavigate();
  const { multiCart, setMultiCartData } = useCarts();
  const { token } = useToken();
  const { serverData } = useServer();
  const query = useQuery();
  const [loadingEventItemData, setloadingEventItemData] = useState(true);
  const [showEventItemModal, setShowEventItemModal] = useState(false);
  const [currentEvent, setCurrentEvent] = useState({});
  const [stadium, setStadium] = useState(null);
  const [cart, setCart] = useState(null);
  const { state } = useLocation();
  const [hasCurrentEventInTheCart, setHasCurrentEventInTheCart] =
    useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [storePassword, setStorePassword] = useState("");
  const [activeStorePassword, setActiveStorePassword] = useState("");
  const [tempStadium, setTempStadium] = useState(null);
  const [tempEvent, setTempEvent] = useState(null);

  useEffect(() => {
    if (serverData) {
      getFullEventAndStore();
    }
  }, [serverData]);

  const getFullEventAndStore = async () => {
    let eventId = null;
    if (state && state.eventId) {
      eventId = state.eventId;
    } else {
      eventId = query.get("gle");
    }

    try {
      const eventAndStoreData = await serverData.call(
        "USSR_getFullEventAndStore",
        {
          eventId: eventId,
        }
      );
      const { evItemDOC, storeDOC } = eventAndStoreData;

      // Before setting stadium, check if this store is password protected.
      if (
        storeDOC?.stadiumPassword?.passwordEnabled &&
        storeDOC?.stadiumPassword?.password &&
        storeDOC?.stadiumPassword?.password.trim() !== ""
      ) {
        // Show password input modal
        setIsPasswordModalOpen(true);
        setActiveStorePassword(storeDOC?.stadiumPassword?.password);
      } else {
        setStadium(storeDOC);
        setCurrentEvent(evItemDOC);
        setloadingEventItemData(false);
        getCart(storeDOC);
      }
      setTempStadium(storeDOC);
      setTempEvent(evItemDOC);
    } catch (e) {
      console.log("Error getting FullEventAndStore", e);
    }
  };

  let isMobile = false;
  const { width } = useWindowDimensions();
  if (screenWidths.md > width) {
    isMobile = true;
  }

  let doorsOpen = "";
  let startsTime = "";
  let endsTime = "";
  let isEventEnded = false;
  if (!loadingEventItemData && currentEvent.evOperatingTime) {
    doorsOpen = formatXTime(currentEvent.evOperatingTime.evOpening);
    startsTime = formatXTime(currentEvent.evOperatingTime.evStartTime);
    endsTime = formatXTime(currentEvent.evOperatingTime.evEndTime);
    const currentTime = new Date();
    isEventEnded = currentTime > currentEvent.evOperatingTime.evEndTime;
  }

  let googleMapLink = "";
  if (!loadingEventItemData && currentEvent.evAddress) {
    googleMapLink = `https://maps.google.com/maps?q=${currentEvent.evAddress.physical}`;
  }

  useEffect(() => {
    if (stadium) {
      getCart(stadium);
    }
  }, [multiCart]);

  const getCart = (stadium) => {
    const stadiumCart = multiCart.find(
      (cart) => cart.stadiumID === stadium._id
    );
    if (stadiumCart) {
      let eventId = null;
      if (state && state.eventId) {
        eventId = state.eventId;
      } else {
        eventId = query.get("gle");
      }

      const sameEvent = stadiumCart.eventItems.find(
        (event) => event.eventItemId === eventId
      );
      if (sameEvent) {
        setHasCurrentEventInTheCart(true);
      }
      setCart(stadiumCart);
    }
  };

  const handlePasswordConfirm = () => {
    if (activeStorePassword === storePassword) {
      // Password entered is correct
      setIsPasswordModalOpen(false);
      setStadium(tempStadium);
      setCurrentEvent(tempEvent);
      setloadingEventItemData(false);
      getCart(tempStadium);
    } else {
      if (!storePassword) {
        message.error("Please input a valid password");
      } else {
        message.error("Incorrect Password.");
      }
    }
  };

  return (
    <Layout style={{ backgroundColor: "white" }}>
      <Modal
        title="Password Protected Event"
        open={isPasswordModalOpen}
        onCancel={() => navigate("/")}
        footer={[
          <Button
            key="back"
            onClick={handlePasswordConfirm}
            style={{ color: "white", backgroundColor: "black" }}
          >
            Confirm
          </Button>,
        ]}
      >
        <p>
          This event is password protected. Please enter the password below to
          view this event.
        </p>
        <Input.Password
          maxLength={25}
          onChange={(e) => setStorePassword(e.target.value)}
          placeholder="Input password"
        />
      </Modal>
      <Content
        style={{
          minHeight: "100vh",
          marginBottom: 100,
          marginTop: 20,
        }}
      >
        <Spin
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          spinning={loadingEventItemData}
          size="large"
        />
        {!loadingEventItemData ? (
          <>
            {currentEvent.evFeaturedImage ? (
              <Row
                style={{
                  backgroundImage: `url(${currentEvent.evFeaturedImage})`,
                  height: isMobile ? 200 : 400,
                  width: "100%",
                  backgroundSize: "contain",
                  backgroundPositionY: "center",
                  backgroundPositionX: "center",
                  backgroundRepeat: "no-repeat",
                  marginBottom: 10,
                }}
              />
            ) : null}
            <Row>
              <Col xs={{ span: 22, offset: 1 }} md={{ span: 16, offset: 4 }}>
                <Title
                  style={{
                    fontSize: isMobile ? 22 : 32,
                    textTransform: "uppercase",
                    textAlign: isMobile ? "center" : "start",
                    paddingTop: 20,
                  }}
                >
                  {currentEvent.evName ? currentEvent.evName : ""}
                </Title>
                <Row>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Title
                      style={{
                        fontSize: isMobile ? 18 : 24,
                        textAlign: isMobile ? "center" : "start",
                        paddingBottom: 20,
                      }}
                    >
                      When and Where?
                    </Title>
                    <Row gutter={[10, 20]}>
                      <Col
                        xs={{ span: 20, offset: 3 }}
                        md={{ span: 24, offset: 0 }}
                      >
                        <Row>
                          <Col>
                            <FaRegCalendar
                              style={{
                                color: token.colorPrimary,
                                fontSize: isMobile ? 32 : 34,
                              }}
                            />
                          </Col>
                          <Col style={{ paddingLeft: 15 }}>
                            <Row>
                              <Text style={{ fontSize: isMobile ? 14 : 16 }}>
                                {currentEvent.evDisplayStrings
                                  ?.evStartDisplayString
                                  ? currentEvent.evDisplayStrings
                                      .evStartDisplayString
                                  : ""}
                              </Text>
                            </Row>
                            <Row>
                              <Text style={{ fontSize: isMobile ? 14 : 16 }}>
                                Doors Open: {doorsOpen}
                              </Text>
                            </Row>
                            <Row>
                              <Text style={{ fontSize: isMobile ? 14 : 16 }}>
                                Starts: {startsTime}, Ends: {endsTime}
                              </Text>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        xs={{ span: 20, offset: 3 }}
                        md={{ span: 24, offset: 0 }}
                      >
                        <Row>
                          <Col>
                            <FaMapMarkerAlt
                              style={{
                                color: token.colorPrimary,
                                fontSize: isMobile ? 32 : 34,
                              }}
                            />
                          </Col>
                          <Col style={{ paddingLeft: 15 }}>
                            <Row>
                              <Text style={{ fontSize: isMobile ? 14 : 16 }}>
                                {currentEvent.evAddress?.physical
                                  ? currentEvent.evAddress.physical
                                  : ""}
                              </Text>
                            </Row>
                            {googleMapLink ? (
                              <Row>
                                <Link href={googleMapLink} target={"blank"}>
                                  <Text
                                    style={{
                                      fontSize: isMobile ? 14 : 16,
                                      color: "gray",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    Show on Map
                                  </Text>
                                </Link>
                              </Row>
                            ) : null}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Title
                      style={{
                        fontSize: isMobile ? 18 : 24,
                        textAlign: isMobile ? "center" : "start",
                        paddingTop: 30,
                      }}
                    >
                      About this Event
                    </Title>
                    <p
                      style={{
                        textAlign: isMobile ? "center" : "justify",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {currentEvent.evDesc ? currentEvent.evDesc : ""}
                    </p>
                  </Col>
                  {!isMobile ? (
                    <>
                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 24 }}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Button
                          disabled={isEventEnded}
                          onClick={() => setShowEventItemModal("true")}
                          style={{
                            position: "relative",
                            marginTop: 20,
                            height: 50,
                            width: 300,
                            fontWeight: "bold",
                            color: "white",
                            fontSize: 18,
                            borderWidth: 0,
                            backgroundColor: isEventEnded
                              ? "lightgray"
                              : token.colorSecondary,
                          }}
                        >
                          {isEventEnded ? "Event Concluded" : "Get Tickets"}
                        </Button>
                      </Col>
                      {hasCurrentEventInTheCart ? (
                        <Col
                          xs={{ span: 24 }}
                          md={{ span: 24 }}
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Button
                            onClick={() =>
                              navigate(
                                `/checkout/${cart.stadiumName.replaceAll(
                                  " ",
                                  "-"
                                )}?glb=${cart.stadiumID}`,
                                {
                                  state: {
                                    cart: cart,
                                  },
                                }
                              )
                            }
                            style={{
                              position: "relative",
                              marginTop: 20,
                              height: 50,
                              width: 300,
                              fontWeight: "bold",
                              color: token.colorSecondary,
                              fontSize: 18,

                              borderWidth: 0,
                              backgroundColor: token.colorPrimary,
                            }}
                          >
                            Checkout
                          </Button>
                        </Col>
                      ) : null}
                    </>
                  ) : null}
                </Row>
              </Col>
            </Row>
            {isMobile ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "rgba(255,255,255,0.5)",
                  position: isMobile ? "fixed" : "relative",
                  bottom: 0,
                  zIndex: 1,
                  width: "100%",
                  padding: 20,
                }}
              >
                <Row style={{ width: "100%", justifyContent: "space-between" }}>
                  <Button
                    disabled={isEventEnded}
                    onClick={() => setShowEventItemModal("true")}
                    style={{
                      height: 50,
                      width: hasCurrentEventInTheCart ? "47%" : "100%",
                      fontWeight: "bold",
                      color: "white",
                      fontSize: 16,
                      borderWidth: 0,
                      backgroundColor: isEventEnded
                        ? "lightgray"
                        : token.colorSecondary,
                    }}
                  >
                    {isEventEnded ? "Event Concluded" : "Get Tickets"}
                  </Button>
                  {hasCurrentEventInTheCart ? (
                    <Button
                      onClick={() =>
                        navigate(
                          `/checkout/${cart.stadiumName.replaceAll(
                            " ",
                            "-"
                          )}?glb=${cart.stadiumID}`,
                          {
                            state: {
                              cart: cart,
                            },
                          }
                        )
                      }
                      style={{
                        height: 50,
                        width: hasCurrentEventInTheCart ? "47%" : "100%",
                        fontWeight: "bold",
                        color: token.colorSecondary,
                        fontSize: 16,
                        borderWidth: 0,
                        backgroundColor: token.colorPrimary,
                      }}
                    >
                      Checkout
                    </Button>
                  ) : null}
                </Row>
              </div>
            ) : null}
            <EventItemModal
              showEventItemModal={showEventItemModal}
              setShowEventItemModal={setShowEventItemModal}
              currentItem={currentEvent}
              stadium={stadium}
            />
          </>
        ) : null}
      </Content>
    </Layout>
  );
};

export default EventOverview;
